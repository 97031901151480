
const fetchVoiceToChatCompletion = async ({uri, userId}) => {
    var projectId = 'WILLRCLINE.COM'
    try {
    // Specify the API endpoint
    const apiUrl = process.env.REACT_APP_API_URL;
    const uploadUrl = `${apiUrl}/v1/voice-to-chat-completion/?userId=${userId}&projectId=${projectId}`;
    
    const formData = new FormData();
    formData.append('file', uri, 'recording.webm');

    // Perform the file upload using fetch
    const response = await fetch(uploadUrl, {
      method: 'POST',
      body: formData,
    });
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const responseData = await response.json();
    console.log("fetchVoiceToChatCompletion after fetch response___", responseData)
    console.log("fetchVoiceToChatCompletion after fetch response chatCompletion___", responseData.chatCompletion)
    return responseData;
    } catch (error) {
        console.error('Error calling function:', error);
    }
};

export default fetchVoiceToChatCompletion;