
const fetchTextToChatCompletion = async ({newText, userId}) => {
    var projectId = 'WILLRCLINE.COM'
    try {
    // Specify the API endpoint
    const apiUrl = process.env.REACT_APP_API_URL;
    const fetchUrl = `${apiUrl}/v1/text-to-chat-completion/?userId=${userId}&projectId=${projectId}`;

    // Perform the file upload using fetch
    const response = await fetch(fetchUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({newText})
    });
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const responseData = await response.json();
    console.log("fetchTextToChatCompletion after fetch response___", responseData)
    console.log("fetchTextToChatCompletion after fetch response chatCompletion___", responseData.chatCompletion)
    return responseData;
    } catch (error) {
        console.error('Error calling function:', error);
    }
};

export default fetchTextToChatCompletion;