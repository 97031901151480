import {useState, useContext, useEffect} from "react";
import { AvatarContext } from "./Avatar.jsx";
import { useSpeakSelectedText } from "../../helper/hooks/useSpeakSelectedText.js";
import { useStartSession } from "../../helper/hooks/AvatarVideoControls.js";
import Colors from "../../helper/Colors.js";
const TriggerStart = ({startBtnClicked, setStartBtnClicked}) => {
    const {sessionStarted, setChatState} = useContext(AvatarContext);
    const speakText = useSpeakSelectedText();
    const startSession = useStartSession()
    const [greetingText, setGreetingText] = useState("Hello, welcome to the embassy. Are you ready to begin the interview?");
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        if (sessionStarted) {
            speakText(greetingText);
        };
    }, [sessionStarted]);

    const handleStartSession = async () => {
        setStartBtnClicked(true);
        startSession();
        setChatState("loading");
        // Enable for dynamic greeting
        // this will need to be a fetch that generates on the server and adds it to the dialog very similar to textToChatCompletion
        // var text = await [function-goes-here]);
        // setGreetingText(text)
    }

    return (
        <>
            {!startBtnClicked && (
                <div 
                style={{
                    position: 'fixed',
                    top: '50%', /* Center vertically */
                    left: '50%', /* Center horizontally */
                    transform: 'translate(-50%, -50%)', // 
                    zIndex: 101,
                    maxWidth: "35rem",
                }}>
                    <p 
                    style = {{
                        cursor: "pointer", 
                        color: Colors.darkGray, 
                        fontSize: 16, 
                        fontFamily: 'Inter',
        fontWeight: 400, 
                        // opacity: isHovered ? 0.5 : 0.35, 
                        zIndex: 101,
                    }} 
                    onClick={handleStartSession}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    >
                        Click here to Begin.<br/>

                        {/* She's my AI Professional Representative. */}

                    </p>
                </div>
            )} 
        </>
    )
}

export default TriggerStart